import router from "@/router";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
    setup() {
        onMounted(() => {
            console.log(router);
            const goRoute = router.currentRoute.value.query.route;
            router.push({
                path: goRoute,
            });
        });
        return {};
    },
});
